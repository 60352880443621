// @flow

import {Controller} from '@hotwired/stimulus';
// eslint-disable-next-line no-unused-vars
import Swiper, {Navigation, Pagination, Autoplay} from 'swiper';

import type {ValueTypes} from './types';
export type ControllerValues = {
  options: ValueTypes,
};

Swiper.use([Navigation, Pagination, Autoplay]);

export default class extends Controller {
  static values: ControllerValues = {
    options: Object,
  };

  connect(): void {
    // We need a tiny delay here because otherwise the heights don't format
    // for the images and it's stuck.
    requestAnimationFrame(() => {
      this.swiper = new Swiper(this.element, {
        ...this.defaultOptions,
        ...this.optionsValue,
      });
    });
  }

  disconnect(): void {
    this.swiper.destroy();
    this.swiper = undefined;
  }

  get defaultOptions(): Object {
    return {};
  }
}
