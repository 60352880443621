// @flow

import {Controller} from '@hotwired/stimulus';
import Chart from 'chart.js/auto';

import type {ValueTypes} from './types';
export type ControllerValues = {
  options: ValueTypes,
  data: ValueTypes,
  type: ValueTypes,
};

export default class extends Controller {
  static targets: Array<string> = ['canvas'];
  static values: ControllerValues = {
    type: String,
    data: Object,
    options: Object,
  };

  connect(): void {
    const element: HTMLCanvasElement = this.hasCanvasTarget
      ? this.canvasTarget
      : this.element;

    const chartOptions = Object.assign({}, this.chartOptions);

    if (chartOptions.__usePercentY) {
      // HACK: Support custom options that cannot be serialized to JSON.
      delete chartOptions.__usePercentY;
      chartOptions['scales'] = chartOptions?.scales ?? {};
      chartOptions.scales['y'] = chartOptions.scales?.y ?? {};
      chartOptions.scales.y['ticks'] = chartOptions.scales.y?.ticks ?? {};
      chartOptions.scales.y.ticks['callback'] = (value, _index, _values) => {
        return `${value}%`;
      };
    }

    this.chart = new Chart(element.getContext('2d'), {
      type: this.typeValue || 'line',
      data: this.chartData,
      options: chartOptions,
    });
  }

  disconnect(): void {
    this.chart.destroy();
    this.chart = undefined;
  }

  get chartData(): Object {
    if (!this.hasDataValue) {
      console.warn(
        '[stimulus-chartjs] You need to pass data as JSON to see the chart.',
      );
    }

    return this.dataValue;
  }

  get chartOptions(): Object {
    return {
      ...this.defaultOptions,
      ...this.optionsValue,
    };
  }

  get defaultOptions(): Object {
    return {};
  }
}
