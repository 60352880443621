import Dropdown from 'stimulus-dropdown';
import {useTransition} from 'stimulus-use/dist/use-transition';

export default class extends Dropdown {
  connect() {
    useTransition(this, {
      element: this.menuTarget,
      hiddenClass: 'is-hidden',
    });
  }

  hide(event: Event): void {
    if (
      !this.element.contains(event.target) &&
      !this.menuTarget.classList.contains('is-hidden')
    ) {
      this.leave();
    }
  }
}
