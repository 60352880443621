// @flow
import {Controller} from '@hotwired/stimulus';

import type {StimulusEvent, ValueTypes} from './types.js';

type ControllerValues = {
  errorMessage: ValueTypes,
};

export function triggerClear(node: ?HTMLElement, options: ?Object): void {
  if (!node) {
    return;
  }
  const extraOptions = options || {};
  node.dispatchEvent(new CustomEvent('clear', extraOptions));
}

export function triggerError(node: ?HTMLElement, options: ?Object): void {
  if (!node) {
    return;
  }
  const extraOptions = options || {};
  node.dispatchEvent(new CustomEvent('error', extraOptions));
}
/**
 * Add data-controller="form-input" to the parent node that has inputs.
 *
 * Captures events for errors and can also clear them.
 * To use you can send the clear or error event with detail { message: ..., messageNode: ... }
 * The controller will add it (only once) or clear that message.
 *
 * You can append a node that acts as the display for the error message.
 * Add is-hidden to this which will be removed when the error is displayed.
 *
 * You can also specify the default error message.
 */
export default class extends Controller {
  static targets: Array<string> = ['submit'];
  static values: ControllerValues = {
    errorMessage: String,
  };

  /**
   * Resets the validity if it has been set.
   */
  onChange(event: StimulusEvent): void {
    const target = event.target;
    if (target instanceof HTMLInputElement) {
      target.setCustomValidity('');
    }
  }

  /**
   * Use the following example to report a custom error message on a HTML5 validation.
   *
   * action: 'invalid->form-input#invalid change->form-input#onChange',
   * form_input_validation_message_param: 'Please enter a valid website',
   */
  invalid(event: StimulusEvent): void {
    if (event.params.validationMessage) {
      const target = event.target;
      target instanceof HTMLInputElement &&
        target.validity.valid === false &&
        target.setCustomValidity(event.params.validationMessage);
    }
  }

  setSubmitToLoading(event: StimulusEvent) {
    this.disableSubmit(event);
    this.submitTarget.classList.add('is-loading');
  }

  disableSubmit(event: StimulusEvent) {
    if (this.submitTarget.tagName !== 'A') {
      this.submitTarget.setAttribute('disabled', 'disable');
    }
  }

  enableSubmit(event: StimulusEvent) {
    if (this.submitTarget.classList.contains('is-loading')) {
      this.submitTarget.classList.remove('is-loading');
    }
    if (this.submitTarget.tagName !== 'A') {
      this.submitTarget.removeAttribute('disabled');
    }
  }

  setInputValue(event: StimulusEvent) {
    if (!event.params.inputName && !event.params.inputId) {
      console.warn('No input name or input id');
      return;
    }
    const element =
      (event.params.inputName &&
        document.getElementsByName(event.params.inputName)[0]) ||
      (event.params.inputId && document.getElementById(event.params.inputId));

    if (element instanceof HTMLInputElement) {
      element.value = event.params.inputValue;
    }
  }

  appendValueToInputByName(event: StimulusEvent) {
    const name = event.params.inputName;
    const newValue = event.params.inputValue;
    const elements = document.getElementsByName(name);
    for (let i = 0; i < elements.length; i++) {
      const element = elements[i];
      if (element instanceof HTMLInputElement) {
        const currentValue = JSON.parse(element.value);
        currentValue.push(newValue);
        element.value = JSON.stringify(currentValue);
      }
    }
  }

  clear(event: StimulusEvent) {
    const messageNode = event.detail?.messageNode;
    const target = event.target;
    if (messageNode) {
      messageNode.classList.add('is-hidden');
      messageNode.classList.remove('xyz-in');
      messageNode.innerHTML = '';
    }
    if (target instanceof HTMLElement) {
      target.classList.remove('is-danger');
    }
  }

  error(event: StimulusEvent): void {
    const target = event.target;
    const message = event.detail?.message;
    const messageNode = event.detail?.messageNode;
    if (messageNode) {
      messageNode.classList.remove('is-hidden');
      messageNode.classList.add('xyz-in');
      messageNode.innerHTML = message;
    }
    if (target instanceof HTMLElement) {
      target.classList.add('is-danger');
    }
  }
}
