// @flow
import {Controller} from '@hotwired/stimulus';
import type {ValueTypes} from './types';

export type ControllerValues = {
  modalId: ValueTypes,
  pushUrl: ValueTypes,
  appearDelay: ValueTypes,
};

const ESC = 27;
/**
 * A controller applied to an HTML node that contains a modal.
 * Using ModalLauncher you can make the modal appear.
 */
export default class extends Controller {
  _hasPushedUrl: boolean = false;

  static classes: Array<string> = ['active'];
  static values: ControllerValues = {
    modalId: String,
    isAsync: Boolean,
    pushUrl: String,
    canDismiss: {type: Boolean, default: true},
    appearDelay: Number,
  };
  static targets: Array<string> = ['content'];

  connect(): void {
    this.headerNode = this.element.querySelector('.message');
    if (this.headerNode) {
      this.initialHeaderNodeContent = this.headerNode.innerHTML;
    }
    if (this.isAsyncValue) {
      this.initialLoadingContent = this.contentTarget.innerHTML;
    }
    this.dispatch('connected', {
      detail: {
        modalId: this.modalIdValue,
      },
    });

    this.handleBackgroundClick = event => {
      if (event.target?.classList?.contains('modal-background')) {
        this.close();
      }
    };

    this.handleKeyUp = event => {
      if (event.keyCode === ESC) {
        this.close();
      }
    };

    if (this.canDismissValue) {
      this.element.addEventListener('click', this.handleBackgroundClick);
      document.addEventListener('keyup', this.handleKeyUp);
    }

    if (this.hasAppearDelayValue) {
      this.timer = setTimeout(() => {
        this.element.classList.add(this.activeClass);
      }, this.appearDelayValue);
    }
  }

  disconnect(): void {
    // If they navigate away from this page make sure we have closed this modal.
    this.close();
    if (this.canDismissValue) {
      this.element.removeEventListener('click', this.handleBackgroundClick);
      document.removeEventListener('keyup', this.handleKeyUp);
    }
    this.timer && clearTimeout(this.timer);
  }

  close(): void {
    document.querySelector('html')?.classList.remove('is-unscrollable');
    this.element.classList.remove(this.activeClass);
    if (this._hasPushedUrl) {
      window.history?.replaceState({}, '', this._beforePushedUrlPathName);
    }
    // If it was shown before and they escape, make sure the timeout won't make it appear again.
    this.timer && clearTimeout(this.timer);
  }

  launch(event: CustomEvent): void {
    const modalId = event?.detail?.modalId;
    const modalHeader = event?.detail.modalHeader;
    const modalUrl = event?.detail.modalUrl;
    const pushUrl = event?.detail.pushUrl;

    // When a launch event is triggered we reset all modal controllers.
    this.element.classList.remove(this.activeClass);
    if (this.initialLoadingContent) {
      this.contentTarget.innerHTML = this.initialLoadingContent;
    }
    if (this.headerNode && this.initialHeaderNodeContent) {
      this.headerNode.innerHTML = this.initialHeaderNodeContent;
    }

    if (this.modalIdValue !== modalId) {
      return;
    }

    if (pushUrl) {
      this._hasPushedUrl = true;
      this._beforePushedUrlPathName = window.location.pathname;
      window.history?.pushState({}, 'company', pushUrl);
    }

    // Now activate only the matching modal.
    document.querySelector('html')?.classList.add('is-unscrollable');
    this.element.classList.add(this.activeClass);
    if (this.headerNode && modalHeader) {
      this.headerNode.innerHTML = modalHeader;
    }

    if (modalUrl) {
      $.get(modalUrl, (data: string) => {
        this.contentTarget.innerHTML = data;
      });
    }

    // If a video exists on the modal, then play it.
    const videoElement = this.element.querySelector('video');
    if (videoElement) {
      videoElement.play();
    }
  }
}
