import {Controller} from '@hotwired/stimulus';

/**
 * If you don't want complicated logic, this can replace a node with another node.
 * This can be used to have an image that is loading to swap with another thing.
 *
 * Listen to the event with event->toggle#swap
 * This will hide the node with data-toggle-target="before" and
 * make data-toggle-target="after" appear.
 */
export default class extends Controller {
  static targets: Array<string> = ['before', 'after'];

  switch(event: any): void {
    if (!event) {
      return;
    }
    const elementId = event?.params?.elementId;
    const newNode = document.getElementById(elementId);
    if (!newNode) {
      return;
    }
    this.element.replaceWith(newNode);
    newNode.classList.remove('is-hidden');
  }

  connect(): void {
    // If the image is already loaded then we trigger the swap
    // immediately.
    const imagesToLoad = this.element.querySelectorAll(
      'img[data-action*="load"]',
    );
    if (imagesToLoad.length > 0) {
      if (
        Array.from(imagesToLoad).every((img: Element) => {
          return (img as HTMLImageElement).complete;
        })
      ) {
        this.showAfter();
      }
    }
  }

  showBefore(): void {
    this.beforeTarget.classList.remove('is-hidden');
    this.afterTarget.classList.add('is-hidden');
  }

  showAfter(): void {
    this.beforeTarget.classList.add('is-hidden');
    this.afterTarget.classList.remove('is-hidden');
  }

  swap(_event?: any): void {
    if (this.beforeTarget.classList.contains('is-hidden')) {
      this.beforeTarget.classList.remove('is-hidden');
      this.afterTarget.classList.add('is-hidden');
    } else {
      this.beforeTarget.classList.add('is-hidden');
      this.afterTarget.classList.remove('is-hidden');
    }
  }

  declare beforeTarget: HTMLElement;
  declare afterTarget: HTMLElement;
  declare readonly hasButtonTarget: boolean;
}
