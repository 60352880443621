import emptyFunction from './emptyFunction';

const ERROR_MESSAGE = `
  Sorry, looks like you experienced an error on this page. Our team has been
  notified and we'll try and fix it as soon as possible. We added some additional
  details below that you may reference in an email to us.
`
  .split('\n')
  .map(x => x.trim())
  .join(' ');

const RequestUtils = {
  csrfToken: () => {
    const node = document.querySelector('meta[name="csrf-token"]');
    if (node instanceof HTMLElement) {
      return node.getAttribute('content');
    }
    console.warn('No CSRF token on page!');
    return null;
  },
  isLoggedIn: () => {
    return Boolean(document.querySelector('body')?.dataset.user);
  },
  isOnPage: (controller, action) => {
    return $('body').hasClass(controller) && $('body').hasClass(action);
  },
  getDialog: (path, onComplete) => {
    $.ajax({
      url: path,
      method: 'GET',
      complete: typeof onComplete === 'function' ? onComplete : emptyFunction,
    });
  },
  successHandler: resp => {
    Turbolinks.visit(resp.redirect_url);
  },
  displayGenericError: (error: ?Error) => {
    const errorAsString = error?.toString();
    ga('send', 'exception', {
      exDescription: errorAsString,
      exFatal: false,
    });
    const message = `${ERROR_MESSAGE}\n\n${errorAsString}`;
    sweetAlert.fire({
      title: 'Uh-oh!',
      text: message,
      type: 'error',
    });
  },
  errorHandler: xhr => {
    let dialogMessage = ERROR_MESSAGE.split('\n')
      .map(x => x.trim())
      .join(' ');
    if (xhr.responseJSON) {
      const dialogHTML = xhr.responseJSON.dialogHTML;
      const jsonError = xhr.responseJSON.error;
      const resourceErrors = xhr.responseJSON.errors;

      if (resourceErrors) {
        const prettyErrorMessage = '';

        dialogMessage = `
          Sorry, looks like something you entered was invalid.
          \n\nError: ${prettyErrorMessage}
        `;
      }

      if (jsonError) {
        if (jsonError.length >= 40) {
          dialogMessage = jsonError;
        } else {
          dialogMessage += `\n\nError: ${jsonError}`;
        }
      }

      sweetAlert.fire({
        title: 'Uh-oh!',
        text: dialogHTML || dialogMessage,
        type: 'error',
        html: Boolean(dialogHTML),
      });
    } else if (
      xhr.getResponseHeader('Content-Type').includes('text/javascript')
    ) {
      // The XHR has javascript that will be executed. Do nothing.
    } else {
      sweetAlert.fire({
        title: 'Uh-oh!',
        text: dialogMessage
          .split('\n')
          .map(x => x.trim())
          .join(' '),
        type: 'error',
      });
    }
  },
};

export default RequestUtils;
