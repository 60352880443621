import Remote from 'stimulus-remote-rails';
import {StimulusEvent} from '../../types';

/**
 * Handles async response handling of an element.
 * If you want to re-render a certain component, add this attribute to the form.
 *
 *  data-action="ajax:success->remote#replace ajax:error->remote#notify"
 *
 * If you want to have a button that shows a loading indicator, add
 * data-remote-target="button" to the button.
 *
 * You can also add it directly to remote links.
 * Example:
 * link_to "test", url, remote: true, data: { controller: "remote" }
 */

export default class extends Remote<HTMLElement> {
  static targets: Array<string> = [
    'button',
    'loadingContent',
    'loadingContentContainer',
  ];

  replace(event: CustomEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const [, , xhr] = event.detail;
    // Only replace if it's HTML.
    // This will handle javascript responses properly.
    if (
      xhr.getResponseHeader('content-type') === 'text/javascript; charset=utf-8'
    ) {
      return;
    }
    this.element.outerHTML = xhr.response;
  }

  connect() {
    super.connect();

    if (
      !this.hasButtonTarget &&
      this.element.tagName !== 'A' &&
      !this.element.classList.contains('can-show-loading-state')
    ) {
      return;
    }

    // This is a FORM controller and MAY have a button.
    // If it does we make add .is-loading to the button if the form is submitted.
    const node = this.hasButtonTarget ? this.buttonTarget : this.element;

    this.onSubmit = () => {
      node.classList.add('is-loading');
      if (node.tagName !== 'A') {
        node.setAttribute('disabled', 'disable');
      }
    };
    this.element.addEventListener('ajax:send', this.onSubmit);

    this.onComplete = () => {
      node.classList.remove('is-loading');
      node.removeAttribute('disabled');
    };
    this.element.addEventListener('ajax:complete', this.onComplete);
  }

  disconnect() {
    this.element.removeEventListener('ajax:send', this.onSubmit);
    this.element.removeEventListener('ajax:complete', this.onComplete);
  }

  /**
   * Submit this form, after it passes validation if this is applied on a form.
   */
  submitForm(event: StimulusEvent): void {
    event.preventDefault();

    let isValid = true;
    if (
      this.element instanceof HTMLFormElement ||
      this.element instanceof HTMLButtonElement
    ) {
      isValid = this.element.reportValidity();
    }
    if (!isValid) {
      return;
    }
    this.element.submit();
  }

  resetButton(): void {
    const node = this.hasButtonTarget ? this.buttonTarget : this.element;
    node.classList.remove('is-loading');
    if (node.tagName !== 'A') {
      node.removeAttribute('disabled');
    }
  }

  setButtonToLoad() {
    const node = this.hasButtonTarget ? this.buttonTarget : this.element;
    node.classList.add('is-loading');
    if (node.tagName !== 'A') {
      node.setAttribute('disabled', 'disable');
    }
  }

  enableButton() {
    const node = this.hasButtonTarget ? this.buttonTarget : this.element;
    node.classList.remove('is-loading');
    node.removeAttribute('disabled');
  }

  /**
   * Useful if you want to clear form inputs after a response comes back.
   * If the respond is a render_notification call, you'll want to clear inputs.
   */
  clearInputs(): void {
    this.element
      .querySelectorAll('input[type="text"], textarea')
      .forEach((input: any) => {
        input.value = '';
      });
  }

  download(event): void {
    const fileDataAsText = event.detail[0];
    const blob = new Blob([fileDataAsText], {type: 'text/csv'});

    const downloadUrl = URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.classList.add('is-hidden');
    a.href = downloadUrl;
    a.download = 'file.csv';
    document.body.appendChild(a);
    a.click();
  }

  refresh(): void {
    Turbolinks.reload();
  }

  /**
   * Triggers a notification that outputs the result of the JSON.
   * Render JSON such as: { error: "Bad data" } or { success: "Nice job!" }
   */
  notify(event: CustomEvent): void {
    event.preventDefault();
    event.stopPropagation();

    const [responseJSON, _message, _xhr] = event.detail;

    let icon = 'error';
    let type = 'error';
    let message = '';
    if (responseJSON.error) {
      message = `Oops - an error occurred: ${responseJSON.error}`;
    } else if (responseJSON.success) {
      icon = 'success';
      message = responseJSON.success;
      type = 'success';
    } else {
      message = "An unhandled error occurred. We've been notified.";
    }

    sweetAlert.fire({
      icon: icon,
      type: type,
      title: message,
      toast: true,
      position: 'top-end',
      showConfirmButton: false,
      showCancelButton: false,
      showCloseButton: true,
      timer: 4000,
      timerProgressBar: true,
      allowOutsideClick: true,
    });
  }

  declare element: HTMLElement;
  declare buttonTarget: HTMLElement;
  declare readonly hasButtonTarget: boolean;
}
