// @flow
import {Controller} from '@hotwired/stimulus';
import RequestUtils from '../util/RequestUtils';

export default class extends Controller {
  static targets: Array<string> = ['expandBtn'];

  loadAndExpand(event: Event): void {
    // Do not trigger the URL.
    // This allows scrapers to register the URL but scrapers will be forwarded to the specific page.
    event.preventDefault();

    this.expand(event);

    // This is an "unexpand" so we do nothing.
    if (this.currentExpandBtn == null) {
      return;
    }

    const target = (event.currentTarget: any);
    if (!(target instanceof HTMLElement)) {
      return;
    }

    // Send a request to get the data.
    $.ajax({
      url: target.getAttribute('href'),
      headers: {
        'Accept': 'text/html; charset=utf-8',
        'Content-Type': 'text/html; charset=utf-8',
      },
      success: resp => {
        const nextSibling = target.nextSibling;
        if (!(nextSibling instanceof HTMLElement)) {
          return;
        }
        nextSibling.innerHTML = resp;
      },
      error: RequestUtils.errorHandler,
    });
  }

  expand(event: Event): void {
    const currentTarget = event.currentTarget;
    if (!(currentTarget instanceof HTMLElement)) {
      return;
    }

    // Reset all other header buttons.
    this.expandBtnTargets.forEach(expandBtn => {
      expandBtn.querySelector('i')?.classList.remove('icon-up-open');
      expandBtn.querySelector('i')?.classList.add('icon-down-open');
    });
    this.element.querySelectorAll('.is-expandable').forEach(htmlNode => {
      htmlNode.classList.remove('is-expanded');
      htmlNode.style.maxHeight = null;
    });

    // If the current expanded button is clicked again, we reset everything above already.
    if (this.currentExpandBtn == event.currentTarget) {
      this.currentExpandBtn = null;
      return;
    }

    // Find the next node which we'll expand.
    const iconNode = currentTarget.querySelector('i');
    if (!(iconNode instanceof HTMLElement)) {
      return;
    }
    const nextSibling = currentTarget?.nextSibling;
    if (!(nextSibling instanceof HTMLElement)) {
      return;
    }
    nextSibling.classList.add('is-expanded');
    nextSibling.style.maxHeight = `${nextSibling.scrollHeight}px`;
    iconNode.classList.remove('icon-down-open');
    iconNode.classList.add('icon-up-open');

    this.currentExpandBtn = event.currentTarget;
  }
}
