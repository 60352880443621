// @flow
import {Controller} from '@hotwired/stimulus';

import type {StimulusEvent, ValueTypes} from './types';

type ControllerValues = {
  selectedTab: ValueTypes,
};

/**
 * Controls a typical Bulma tab setup. This controller will keep track of the elements
 * that contain tab content (via data-tabs-target="tabContent") and headers (via .tabs).
 *
 * Can be used for non-Bulma tabs as well.
 * Add data-tabs-target="tabHeader" to your header tags.
 *
 * Add the data-tab-value on the `li` elements under `.tabs`
 * Add the data-tab-value on the element with `data-tabs-target="tabContent"
 *
 * When a tab header element is clicked, it will hide all tabs and reset tab headers.
 * Then it will show the one that is actived.
 *
 * If you want your tab to be loaded async, use "data-tabs-async-url-param" on the
 * header node. This will fire a GET request to fetch the data.
 */
export default class extends Controller {
  static classes: Array<string> = ['tabHeaderActive'];
  static values: ControllerValues = {
    selectedTab: String,
    /**
     * Set to true if you want to make the History API update the page URL after
     * each tab navigation.
     */
    canReplaceUrl: {
      type: Boolean,
      default: false,
    },
  };
  static targets: Array<string> = ['tabContent', 'loaderContent', 'tabHeader'];

  connect(): void {
    // Caches the content of each tab.
    this.localTabCache = {};
    if (!this.hasSelectedTabValue) {
      // If no selected tab, we pick the first tab.
      const firstTab = this.element.querySelector('[data-tab-value]');
      if (!firstTab) {
        console.warn('No tab detected. Did you add data-tab-value?');
        return;
      }
      this.selectedTabValue = firstTab.dataset.tabValue;
    } else {
      // Check if hash is in the URL.
      // We override the selectedTabValue if it is.
      const urlHash = window.location.hash;
      if (urlHash) {
        const tabFromUrlHash = urlHash.substring(1); // Remove #
        const tabNodes = this.element.querySelectorAll(
          '.tabs > ul > li[data-tab-value]',
        );
        // $FlowIgnore
        const validTabNames = [].map.call(
          tabNodes,
          node => node.dataset.tabValue,
        );
        if (validTabNames.includes(tabFromUrlHash)) {
          this.selectedTabValue = tabFromUrlHash;
        }
      }

      const currentTabHeaderNode = this.element.querySelector(
        `.tabs > ul > li[data-tab-value="${this.selectedTabValue}"]`,
      );

      const currentTabHeaderAsyncURL =
        currentTabHeaderNode &&
        currentTabHeaderNode
          .querySelector('a')
          ?.getAttribute('data-tabs-async-url-param');

      if (currentTabHeaderAsyncURL) {
        this._fetchTabContent(currentTabHeaderAsyncURL);
      }
    }
    this._renderTabs();
  }

  changeTab(event: StimulusEvent): void {
    const tab = event.params?.tab;
    const url = event.params?.asyncUrl;
    this.selectedTabValue = tab;
    this._renderTabs();
    if (url) {
      this._fetchTabContent(url);
    }

    if (this.canReplaceUrlValue) {
      window.history?.replaceState(history.state, '', `#${tab}`);
    }

    event.preventDefault();
  }

  _fetchTabContent(url: string): void {
    const currentTabContentNode = this.tabContentTargets.find(node => {
      return node.dataset.tabValue === this.selectedTabValue;
    });

    if (this.localTabCache[url]) {
      currentTabContentNode.innerHTML = this.localTabCache[url];
      return;
    }

    currentTabContentNode.innerHTML = this.loaderContentTarget.innerHTML;
    $.ajax({
      url: url,
      error: _ => {
        currentTabContentNode.innerHTML =
          '<div class="notification is-danger is-light">Something went wrong. Our team has been notified.</div>';
      },
      success: resp => {
        this.localTabCache[url] = resp;
        currentTabContentNode.innerHTML = resp;
      },
    });
  }

  /**
   * Remove the active class from all header nodes.
   * Hide all the tabContent nodes.
   * Prepares the tabs to render with new state.
   */
  _resetTabs(): void {
    if (this.hasTabHeaderTarget) {
      this.tabHeaderTargets.forEach(node => {
        node.classList.remove('is-active');
      });
    } else {
      this.element.querySelectorAll('.tabs > ul > li').forEach(node => {
        node.classList.remove('is-active');
      });
    }
    this.tabContentTargets.forEach(node => {
      node.classList.add('is-hidden');
    });
  }

  _renderTabs(): void {
    this._resetTabs();
    const currentTabContentNode = this.tabContentTargets.find(node => {
      return node.dataset.tabValue === this.selectedTabValue;
    });

    currentTabContentNode?.classList.remove('is-hidden');

    if (this.hasTabHeaderTarget) {
      const currentTabHeaderNode = this.tabHeaderTargets.find(node => {
        return node.dataset.tabValue === this.selectedTabValue;
      });
      if (this.hasTabHeaderActiveClass) {
        currentTabHeaderNode.classList.add(this.tabHeaderActiveClass);
      } else {
        currentTabHeaderNode.classList.add('is-active');
      }
    } else {
      const currentTabHeaderNode = this.element.querySelector(
        `.tabs > ul > li[data-tab-value="${this.selectedTabValue}"]`,
      );
      currentTabHeaderNode?.classList.add('is-active');
    }
  }
}
