// @flow

import type {ValueTypes} from './types';

import {Controller} from 'stimulus';
import {Grid, html} from 'gridjs';
import numeral from 'numeral';
import 'gridjs/dist/theme/mermaid.css';

type ControllerValues = {
  options: ValueTypes,
  fromId: ValueTypes,
};

/**
 * Creates a GridJS for Stimulus.
 * Augments the configuration such that you can add __isHTML or __isFormattedNumber and it'll do
 * some dynamic processing to make that possible. GridJS expects raw data points, so this processing
 * adds some sugar.
 */
export default class GridController extends Controller {
  static values: ControllerValues = {
    options: Object,
    fromId: String,
  };

  disconnect(): void {
    this.grid = null;
  }

  connect() {
    const fromOption = this.fromIdValue
      ? {from: document.getElementById(this.fromIdValue)}
      : {};

    const columns = this.optionsValue.columns.map(column => {
      if (column.__isHeaderHTML) {
        column.name = html(column.name);
        delete column['__isHeaderHTML'];
      }
      if (column.__isHTML) {
        column.formatter = (cell, _) => {
          return html(cell);
        };
        delete column['__isHTML'];
      }
      if (column.__isFormattedNumber) {
        column.formatter = (cell, _) => {
          return numeral(cell).format('0,0');
        };
        delete column['__isFormattedNumber'];
      }
      if (column.__isHTMLWithNumber) {
        if (column.sort?.enabled) {
          column.sort.compare = function (a, b) {
            const aAmount = a?.[1];
            const bAmount = b?.[1];
            if (aAmount == null || bAmount == null) {
              return 0;
            }

            if (aAmount > bAmount) {
              return -1;
            } else if (bAmount > aAmount) {
              return 1;
            } else {
              return 0;
            }
          };
        }
        column.formatter = (cell, _) => {
          return html(cell[0]);
        };
        delete column['__isHTMLWithNumber'];
      }
      if (column.__isFormattedCurrency) {
        if (column.sort?.enabled) {
          column.sort.compare = function (a, b) {
            const aAmount = a?.amount;
            const bAmount = b?.amount;
            if (!aAmount || !bAmount) {
              return 0;
            }

            if (aAmount > bAmount) {
              return 1;
            } else if (bAmount > aAmount) {
              return -1;
            } else {
              return 0;
            }
          };
        }
        column.formatter = (cell, _) => {
          if (!cell || !cell.amount) {
            return '--';
          }
          if (
            cell.currency_code == 'USD' ||
            cell.currency_code == 'AUD' ||
            cell.currency_code == 'CAD' ||
            cell.currency_code == 'NZD' ||
            !cell.currency_code
          ) {
            return `${numeral(cell.amount).format('$0,0')}/mo`;
          } else if (cell.currency_code == 'GBP' || !cell.currency_code) {
            return `£${numeral(cell.amount).format('0,0')}/mo`;
          } else if (cell.currency_code == 'EUR' || !cell.currency_code) {
            return `€${numeral(cell.amount).format('0,0')}/mo`;
          }
          return `${numeral(cell.amount).format('0,0')} ${
            cell.currency_code
          }/mo`;
        };
        delete column['__isFormattedCurrency'];
      }
      return column;
    });

    this.grid = new Grid({
      ...this.optionsValue,
      columns: columns,
      ...fromOption,
    });
    this.grid.render(this.element);

    // Restart Stimulus to fix an issue if we just rendered GridJS.
    setTimeout(() => {
      window.Stimulus && window.Stimulus.stop();
      window.Stimulus && window.Stimulus.start();
    }, 0);
  }
}
